import React from "react"
import { Link } from "gatsby"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/Company"

const MountKatthaPage = () => (
  <Layout showOnlyHome={true}>
    <SEO title="Mount Everest Kattha Mills Pvt. Ltd" />
    <Company
      pdf={[
        {
          title: "Company profile",
          src: "https://rokkagroup.com/assets/kattha.pdf",
        },
      ]}
      title="Mount Everest Kattha Mills Pvt. Ltd"
      phone="+977-012323434"
      email="Email: info@gajurmukhiherbal.com "
    >
      <div className="images">
        <Zoom>
          <img src="/company/everest.jpg" />
        </Zoom>
        <Zoom>
          <img src="/company/everest1.jpg" />
        </Zoom>
      </div>
      <div className="content">
        <h5>Company Detail</h5>
        <p>
          All kinds of wooden business wood treatment, wood seasoning and
          manufacturing of furniture, ply & international class of parquet
          Export & Import.
        </p>
        <h5>Objective</h5>
        <ul>
          <li>
            Purchase of wood from the district forest office, community forest
            office, personal forest and import raw wooden logs, raw woods and
            size lumber from different countries, like Vietnam, Indonesia,
            Malaysia, Myanmar, Laos, Cambodia, South Africa, Canada And many
            more.
          </li>
          <li>
            Sawing of wood logs (To Make Size), Treatment, Seasoning of wood,
            and making of wooden furniture, wooden parquet, doors n windows
            frame and overall finishing, making ply board etc.
          </li>
          <li>
            Marketing of wood &amp;wooden products in Nepal as well as third
            countries.
          </li>
        </ul>
        <address>
          OCR Registration No.: 46744/063/064
          <br />
          PAN/VAT No.: 304465125
          <br />
          Year of Establishment: 2007
          <br />
          Corporate Address: Madhabaliya ward no.- 9, Rupandehi,
          <br />
          Bhairahawa, Nepal
        </address>
        <h5> Key Persons: Mr. Basanta Rokka Chhetri </h5>
        <ul>
          <li>Mr. Kumar Rokka Chhetri (Chairman)</li>
          <li>Mr. Basanta Rokka Chhetri (Director)</li>
          <li>Mr. Bharat Bdr Rokka Chhetri (Director)</li>
          <li>Mr. Ishowar Bdr Rokka Chhetri (Director)</li>
          Mr. Deepak Rokka (Director)
        </ul>
      </div>
      <div className="map">
        <iframe
          id="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.854328333735!2d83.48695441505902!3d27.567031082850168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39969b4133cdb693%3A0xbe047a10f094e2f7!2sMount%20Everest%20Kattha%20Mills%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1585976627827!5m2!1sen!2snp"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
    </Company>
  </Layout>
)

export default MountKatthaPage
